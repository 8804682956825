'use client';

import { env } from '@prismo-io/env';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import {
  type FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

type HubspotContextT = {
  isReady: boolean;
  chatIsVisible: boolean;
  unreadCount: number;
  openChat: () => void;
  closeChat: () => void;
};

const HubspotContext = createContext<HubspotContextT | undefined>(undefined);

type Props = {
  nonce?: string;
};

export const HubspotProvider: FC<PropsWithChildren<Props>> = (props) => {
  const { children, nonce } = props;

  const [isReady, setIsReady] = useState<boolean>(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [chatIsVisible, setChatIsVisible] = useState<boolean>(false);

  const pathname = usePathname();

  useEffect(() => {
    (window as any)['hsConversationsSettings'] = {
      loadImmediately: false,
      inlineEmbedSelector: '#hs-chat-element-custom',
      enableWidgetCookieBanner: true,
    };

    (window as any)['hsConversationsOnReady'] = [
      () => {
        const { HubSpotConversations } = window as any;

        HubSpotConversations.on(
          'unreadConversationCountChanged',
          (payload: any) => {
            setUnreadCount(payload.unreadCount);
          }
        );

        HubSpotConversations.widget.load();
      },
    ];
  }, []);

  useEffect(() => {
    if (isReady && pathname) {
      const { _hsq = [] } = window as any;

      if (_hsq) {
        _hsq.push(['setPath', pathname]);
        _hsq.push(['trackPageView']);
      }

      const { HubSpotConversations } = window as any;

      if (HubSpotConversations && HubSpotConversations.widget) {
        const status = HubSpotConversations.widget.status();

        if (status.loaded) {
          HubSpotConversations.widget.refresh();
        } else {
          HubSpotConversations.widget.load();
        }
      }
    }
  }, [isReady, pathname]);

  // useEffect(
  //     () => {
  //         const { HubSpotConversations } = window as any;

  //         if (HubSpotConversations && HubSpotConversations.widget) {

  //             const status = HubSpotConversations.widget.status();

  //             if (status.loaded) {

  //                 // HubSpotConversations.widget.remove();
  //             } else {
  //                 HubSpotConversations.widget.load();
  //             }
  //         }
  //     },
  //     [chatIsVisible]
  // );

  const openChat = useCallback(() => {
    setChatIsVisible(true);
  }, []);

  const closeChat = useCallback(() => {
    setChatIsVisible(false);
  }, []);

  return (
    <HubspotContext.Provider
      value={{ isReady, unreadCount, chatIsVisible, openChat, closeChat }}
    >
      <>
        <Script
          id="hs-script-loader"
          src={env.NEXT_PUBLIC_HUBSPOT_SCRIPT}
          type="text/javascript"
          strategy="afterInteractive"
          nonce={nonce}
          async
          defer
          onReady={() => setIsReady(true)}
        />
        {children}
      </>
    </HubspotContext.Provider>
  );
};

export const useHubspot = () => {
  const ctx = useContext(HubspotContext);

  if (!ctx) {
    throw new Error('useHubspot() must be used insidd <HubspotProvider />');
  }

  return ctx;
};

'use client';

import { AppPalettes, DesignSystemProvider } from '@prismo-io/design-system';
import '@prismo-io/design-system/module-augmentation';
import { env } from '@prismo-io/env';
import { SessionListener } from '@prismo-io/feature-auth/session-listener';
import { AppType, type LanguageCodeT } from '@prismo-io/schemas';
import { NpsSurveyProvider, UsermavenProvider } from '@prismo-io/tracking';
import type { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  sessionAccessToken?: string;
  locale: LanguageCodeT;
};

export const GlobalProviders: FC<Props> = (props) => {
  const { children, locale, sessionAccessToken } = props;

  return (
    <UsermavenProvider apikey={env.NEXT_PUBLIC_USERMAVEN_KEY} withPageTracking>
      <>
        <SessionListener serverAccessToken={sessionAccessToken} />
        <DesignSystemProvider
          theme={AppPalettes.light}
          options={{ key: 'mui' }}
        >
          <NpsSurveyProvider app={AppType.Enum.APP}>
            {children}
          </NpsSurveyProvider>
        </DesignSystemProvider>
      </>
    </UsermavenProvider>
  );
};
